import React from 'react';

class Table extends React.Component {
    render() {
        return (
            <table className="table">
                <caption>{this.props.caption}</caption>
            
                <thead className="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Meaning</th>
                </tr>
                </thead>
                <tbody>
                     { console.log(this.props.data) }
                     { this.props.data.map( (item, index) => {
                         return (
                            <tr key={index}>
                                <td>{ index } </td>
                                <td>{ item.name }</td>
                                <td>{ item.meaning }</td>
                            </tr>   
                         )
                     })}
                </tbody>
            </table>
        );
    }
}

export default Table;