import React from 'react';
import '../assets/css/PhotoCard.css';


class PhotoCard extends React.Component {
    render() {
        return (
                <div className="card photo-card">
                    <img src={this.props.imgSrc} alt={this.props.imgAlt} className="card-img-top"/>
                    <div className="card-block">
                        <h6 className="card-title title-bg">{this.props.date}</h6>
                        <p className="card-text">{this.props.info}</p>
                    </div>
                </div>
            )
    }
}

export default PhotoCard;