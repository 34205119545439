import React from "react";
import "../assets/css/EventsFeed.css";
import Event from "./Event.js";
import moment from "moment";

import { getEvents } from "../modules/gcalApi";

class EventsFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: []
    };
    this.getDay = this.getDay.bind(this);
  }

  componentDidMount() {
    getEvents(events => {
      console.log("componentDidMount");
      this.setState({ events });
    });
  }

  // for sorting comparison
  sortByDate = date => {
    let newDate = moment(date).format("mm/dd/yyyy");
    console.log("SORTED DATE: " + newDate);
    return newDate;
  };

  getDayOfYear = date => {
    let doy = moment(date).format("DDD");
    console.log("DAY OF YEAR: " + doy);
    return doy;
  };

  // Date/Time conversions
  getDay(date) {
    let day = moment(date).format("DD");
    console.log("DAY : " + day);
    return day;
  }

  getMonth = date => {
    let month = moment(date).format("MMMM");
    console.log("MONTH: " + month);
    return month;
  };

  getYear = date => {
    let year = moment(date).format("YYYY");
    console.log("YEAR: " + year);
    return year;
  };

  getTime = date => {
    let time = moment(date).format("h:mm a");
    console.log("TIME: " + time);
    return time;
  };

  render() {
    return (
      <div className="col-12">
        <div className="card-header">
          <h3 className="card-title">
            <i className="fa fa-calendar-o" aria-hidden="true"></i> Upcoming
            Schedule
          </h3>
        </div>

        <div className="card-body">
          <ul className="events-ul d-flex flex-wrap">
            <iframe
              src="https://calendar.google.com/calendar/b/2/embed?showTitle=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=sensei.nckd%40gmail.com&amp;color=%231B887A&amp;ctz=America%2FLos_Angeles"
              width="800"
              height="600"
              frameborder="0"
              scrolling="no"
              title="calendar"
            ></iframe>
          </ul>
        </div>
      </div>
    );
  }
}

/*
    {
                        this.state.events.sort((a,b) => this.sortByDate(a.start) - this.sortByDate(b.start)).map( (event, index) => {
                                return(
                                    <Event key={index} month={ this.getMonth(event.start)} day={ this.getDay(event.start)} time={ this.getTime(event.start) } title={event.title}  />
                                )
                            })
                        }
*/
export default EventsFeed;
