import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route } from 'react-router-dom';

import './assets/css/index.css';

import HomePage from './pages/HomePage.js';
import PricingPage from './pages/PricingPage.js';
import SchedulePage from './pages/SchedulePage.js';
import GettingStarted from './pages/GettingStartedPage.js';
import KaratePage from './pages/KaratePage.js';
import ContactPage from './pages/ContactPage.js';
import AboutPage from './pages/AboutPage.js';


ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <div>
        <Route exact path="/" component={HomePage} />
        <Route path="/schedule" component={SchedulePage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/karate" component={KaratePage} />
        <Route path="/gettingstarted" component={GettingStarted} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/about" component={AboutPage} />
    </div>
  </BrowserRouter>, 
  document.getElementById('root')
);
registerServiceWorker();
