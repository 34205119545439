import React from 'react';
import '../assets/css/Instructors.css';
import Ryan from '../assets/img/content/ryan_karate.jpg';
import Shaminda from '../assets/img/content/shaminda_karate.jpg';

class Instructors extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-lg-offset-3 text-center">
                    <h2><span className="ion-minus"></span>Our Team of Instructors<span className="ion-minus"></span></h2>
                    <p>Meet some of our senior instructors and see what they can bring to the class.</p><br/>
                </div>
                </div>

                <div className="d-flex flex-row justify-content-center text-center">

                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 member-profile">
                    	   	<img className="img-rounded member-pic" alt="shaminda pic" src={Shaminda} width="75%"/>
                    	   	<div className="team-member">
                        	   	<h4>Shaminda</h4>
                        	   	<p>Shaminda has a third degree black belt in Seito Shito Ryu, and also holds a 2nd degree black belt in Shotokan karate. He brings a breadth of experience and enthusiasm everytime he is in the classroom.</p>
                    	   	</div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 member-profile">
                        <img className="img-rounded member-pic" alt="ryan pic" src={Ryan} width="75%"/>
                        <div className="team-member">
                            <h4>Ryan</h4>
                            <p>Ryan is a 3rd degree black belt (san dan) in Shito Ryu and has 25+ years of experience including National and International competitive experience with the USA Jr and Sr National team (NKF and WKF). He is also enthusiastic for other martial arts,
                                including but not limited to Kickboxing, BJJ, and other self-defense arts.</p>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Instructors;
