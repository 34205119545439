import React from 'react';
import request from 'superagent';
import '../assets/css/InstagramFeed.css';
import PhotoCard from './PhotoCard.js';

const API_KEY = process.env.REACT_APP_INSTAGRAM_API_KEY;
console.log("API: " + API_KEY);
const url =`https://api.instagram.com/v1/users/self/media/recent/?access_token=${API_KEY}`;

class InstagramFeed extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            photos: []
        }
        this.fetchPhotos.bind(this);
    }
    
    componentDidMount() {
        this.fetchPhotos();
    }
    
    //todo: abstract api call
    fetchPhotos() {
      request
        .get(url)
        .then((res) => {
          console.log(res)
          this.setState({
              photos: res.body.data
          })
        })
        .catch((err) => {
            console.log(err)
        })
    }
    
    getDate = (created_time) => {
        let date = new Date(created_time * 1000);
        return date.toString();
        //return date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear() + "," + date.getHours() + ":" + date.getMinutes();
    }
    
    render() {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ig-container">
                <div className="ig-header">
                    <h3>Instagram</h3>
                </div>
    
                <div className="ig-body d-flex flex-row">
                     {this.state.photos.map((photo) => {
                        return (
                            <PhotoCard
                                key={photo.id}
                                imgSrc={photo.images.standard_resolution.url} 
                                imgAlt={photo.caption}
                                date={this.getDate(photo.created_time)}
                                info={photo.caption !== null ? photo.caption.text : ''}/>
                        )
                    })}
                </div>
            </div>
        );
    }
}


export default InstagramFeed;