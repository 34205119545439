import React from 'react';
import '../assets/css/BodyGettingStarted.css';

class render extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="border-0">
                    <div className="card mb-3">
                        <div className="card-header"><h5 className="card-title">New Student?</h5></div>
                        <div className="card-body">

                            <p className="card-text">Whether you're experienced in martial arts or not, we encourage you to
                                contact us today and begin your first steps into traditional karate! Feel free to call or email us today.

                                Our classes are conducted in the Walnut Creek Lynch Fitness Center. We use both wood flooring and
                                sports mats, as well as a wide variety of professional training equipment, to get our students in great shape.
                                Please do not hesitate to ask us any questions!

                            </p>

                        </div>
                        <div className="row text-center">

                                <div className="col-sm-6">
                                    <div className="action-btn btn"><a href="tel:9256832808">Call Us Today</a></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="action-btn btn"><a href="mailto:sensei@norcalkaratedo.org">Email Us Today</a></div>
                                </div>
                                <div>
                                    <br/>
                                    <br/>
                                </div>

                        </div>

                    </div>
                    <div className="card">
                        <div className="card-header"><h5 className="card-title">Visiting?</h5></div>
                        <div className="card-body">

                            <p className="card-text">Are you visiting family or friends? Please contact us today for more information on drop-in classes.</p>
                        </div>
                    </div>

                    <div>
                        <br/>
                        <h3>FAQ</h3>
                        <ul className="list-group">
                          <li className="list-group-item list-group-item-primary">What clothes do I need to wear?</li>
                          <li className="list-group-item">A traditional Karate Gi and belt is required, but if you do not have one yet, you are welcome to wear comfortable gym attire for your first day.
                                    <br/>
                                    For privacy: you are welcome to wear an undershirt. Please wear a plain white shirt or a sports shirt, like Underarmor. No shirts with logos and/or designs.
                            </li>
                          <li className="list-group-item list-group-item-success">Where can I order a Karate gi?</li>
                          <li className="list-group-item">You can order one through us, we tend to order from Shureido, Arawaza, and Adidas. You are also welcome to order from any brand on your own, but
                                                            please make sure the gi is plain white with no prominent labels and/or designs. If you are uncertain, please ask us to review the gi to ensure it is acceptable.</li>
                          <li className="list-group-item list-group-item-primary">How do I register for my first class?</li>
                          <li className="list-group-item">Simply get in touch with us by phone and/or email and we'll get you set up with a payment schedule. If you are looking to register for the Summer Youth Programs, please go to the
                                                                Walnut Creek Community Center website and register through their service.</li>


                          <li className="list-group-item list-group-item-success">How do I pay for my first month?</li>
                          <li className="list-group-item">We currently accept cash and checks, but we are hoping to implement some form of electronic payments in the future.</li>
                          <li className="list-group-item list-group-item-primary">Do I need to bring anything to my first class?</li>
                          <li className="list-group-item">Besides a Karate Gi and belt, we recommend bringing your own water and/or towel. Beginning students do not need to worry about
                          sparring equipment.</li>
                        </ul>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default render;
