import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodyHome from '../components/BodyHome.js';

import '../assets/css/Body.css';


class HomePage extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <BodyHome />  
        <Footer />
      </div>
    );
  }
}

export default HomePage;