import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodyAbout from '../components/BodyAbout.js';

class AboutPage extends React.Component {
    render() {
        return (
            <div> 
                <Header />
                <BodyAbout />
                <Footer />
            </div>
        );
    }
}

export default AboutPage;