import React from 'react';
import '../assets/css/BodyKarate.css';
import '../assets/css/General.css';
import SectionHeader from './SectionHeader.js';
import { Switch, Route, NavLink } from 'react-router-dom'

import Kata from './karate-menu/Kata.js';
import Strikes from './karate-menu/Strikes.js';
import Blocks from './karate-menu/Blocks.js';
import Language from './karate-menu/Language.js';
import Concepts from './karate-menu/Concepts.js';
import History from './karate-menu/History.js';
import Stances from './karate-menu/Stances.js';

class BodyKarate extends React.Component {

    render() {
        return (
            <div className="karate-container">
                <SectionHeader title="Karate" />
                
                <div className="container justify-content-center">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <NavLink exact to="/karate" className="nav-link">History</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/karate/kata" className="nav-link">Kata</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/karate/strikes" className="nav-link">Strikes</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/karate/blocks" className="nav-link">Blocks</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/karate/stances" className="nav-link">Stances</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/karate/concepts" className="nav-link">Concepts</NavLink>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink to="/karate/language" className="nav-link">Language</NavLink>
                      </li>
                    </ul>
                    
                </div>
                
                <Switch>
                    <Route exact path='/karate' component={History}/>
                    <Route path='/karate/kata' component={Kata}/>
                    <Route path='/karate/strikes' component={Strikes}/>
                    <Route path='/karate/blocks' component={Blocks}/>
                    <Route path='/karate/concepts' component={Concepts}/>
                    <Route path='/karate/stances' component={Stances}/>
                    <Route path='/karate/language' component={Language}/>
                </Switch>
                
                <SectionHeader title="Files and Documents" />
    
                <div className="container">
                    <div className="alert alert-secondary" role="alert">
                        Particular files may not be available to the public. If you are a student, please contact us for specific needs.
                    </div>
                    <div className="card border-0">
                        <p className="card-title">Documents For New and Current Members</p>
                        <p className="card-text">For Belt Promotion, Test, or Tuition Fees, please send a request by email to
                        <a href="mailto:sensei.nckd@gmail.com"> sensei.nckd@gmail.com</a></p>
                        <p><a href="https://drive.google.com/file/d/0B6Y4VfWmDEkJS1ZwbC01ZktpRVE/view" target="new">Promotion Requirements</a></p>
                        <p><a href="http://www.seitoshitoryu.com/data/kata_list.pdf">Kata List - Japanese Only (日本語)</a></p>
                        <p><a href="http://www.seitoshitoryu.com/data/syllabus/Syllabus_English.pdf">Seito Shito-Ryu Syllabus (English)</a></p>
                        <p><a href="http://www.seitoshitoryu.com/data/syllabus/Syllabus_Japanese.pdf">Seito Shito-Ryu Syllabus (日本語)</a></p>
                    </div>
                </div>
            </div>
        );
    }
    
};

export default BodyKarate;