import React from 'react';

class History extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="border-0">
                    <div className="col-md-12">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                      <div className="card-body d-flex flex-column align-items-start">
                        
                        <strong className="d-inline-block mb-2 text-primary">Founder</strong>
                        <h3 className="mb-0">
                          <a className="text-dark" href="https://en.wikipedia.org/wiki/Kenwa_Mabuni">Master Kenwa Mabuni</a>
                        </h3>
                        <div className="mb-1 text-muted">1889-1952</div>
                        <p className="card-text mb-auto">Born in Shuri, Okinawa in 1889, Mabuni began his training under the legendary
                            Anko Itosu (1831-1915). 
                            
                            In 1909 (Meiji 42) Itosu told Mabuni to expand his knowledge with other teachers. 
                            Mabuni knew Miyagi Chôjun (1888-1953) already from school and knew him to be a Karate-student of 
                            Higashionna Kanryô (1853-1916). Finally, Miyagi made the contact in between the two and 
                            Higashionna accepted Mabuni as a student. From that moment on and for the following two years, 
                            Mabuni trained parallel with Itosu and Higashionna. Higashionna taught Mabuni the Naha-Te and Mabuni
                            returned the favour with an outstanding showing of dedication. 
                        </p>
                        <p>In 1907 (Meiji 40) Mabuni began to work as a substitute teacher at an elementary school in Naha. Two years later he was drafted 
                        into military service in Kumamoto. This experience should have induced him to start an apprenticeship at the local Police 
                        Academy in 1912 (Meiji 45/ Taishô 1). There Mabuni was also taught some Kendô and Jûdô. After he passed, he became a 
                        Police Inspector in Naha in 1915 (Taishô 4). It was the same year that his long-lived teacher Itosu Ankô died. To pay 
                        homage to his teacher, Mabuni practiced Kata at Itosu’s grave on a daily basis for an entire year.
                        </p>
                        <p>Through the teachings of many legendary figures in Karate history (some that were not mentioned above), Mabuni eventually 
                            came to become the founder of Shito Ryu karate. 
                        </p>
                        <a href="http://www.karatebyjesse.com/legends-of-karate-mabuni-kenwa-and-his-shito-ryu-pt-1/" target="_new">Exerpts from karatebyjesse.com</a>
                      </div>
                      <img className="card-img-right flex-auto d-none d-md-block" src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Mabuni_Kenwa.jpg" alt="mabuni"/>
                    </div>
                  </div>


                <div className="col-md-12">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                      <div className="card-body d-flex flex-column align-items-start">
                        
                        <strong className="d-inline-block mb-2 text-primary">2nd Soke</strong>
                        <h3 className="mb-0">
                          <a className="text-dark" href="http://www.seitoshitoryu.com/soke.php" target="_new">Master Kenzo Mabuni</a>
                        </h3>
                        <div className="mb-1 text-muted">1889-1952</div>
                        <p className="card-text mb-auto">
                        SOKE KENZO MABUNI is the son of the founder of SHITO-RYU KARATE-DO, the late KENWA MABUNI.
                        </p>
                        <p>KENZO MABUNI was born on May 30, 1927 at Akahira-Machi, Shuri City, in Okinawa. His family 
                        moved to Osaka City in 1929, when he was 2 years old, and he remained in his father's house until 
                        now. He obtained permission from his father to join his school when he was 13 years old and 
                        therefore has studied SHITO-RYU KARATE-DO for over 60 years. He first obtained his 
                        SHODAN (1st Dan) on August 1, 1943 and presently holds the rank of JYUDAN (10th Dan) and 
                        is a well respected master not only in Japan but also throughout the world.
                        </p>
                        <p>
                        His organization, NIPPON KARATE DO KAI (formerly known as DAI NIPPON KARATE DO KAI), 
                        was founded by his father in 1939. After his father's death in 1952, his mother, Mrs. KAME MABUNI, 
                        came to Kenzo Mabuni and requested that he take over the style. Kenzo Mabuni could not decide at that 
                        time and went into seclusion for two years to contemplate this great responsibility. Of course, as we all
                        know, he decided to accept this responsibility and is the inheritor of his father's lineage, making him
                        the 2nd Governor of SHITO-RYU and successor to this organization. His father left him the SHITO-RYU name, 
                        his complete syllabus, and the DOJO with the Association name NIPPON KARATE DO KAI. All these remain intact 
                        to this day. He followed his father's syllabus exactly the way it was written down in 1929 and that's why he 
                        called it SEITO SHITO-RYU, or PURE, TRUE SHITO-RYU.
                        </p>
                        <a href="http://www.seitoshitoryu.com/soke.php" target="_new">Exerpts from seitoshitoryu.com</a>
                      </div>
                      <img className="card-img-right flex-auto d-none d-md-block" src="http://www.karatekobudo.com/images/profiles/Grand%20Masters%20-%20Karate/MabuniKenzo1998.jpg" alt="kenzo"/>
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default History;