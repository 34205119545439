import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodyContact from '../components/BodyContact.js';

class ContactPage extends React.Component {
    render() {
        return (
            <div> 
                <Header />
                <BodyContact />
                <Footer />
            </div>
        );
    }
}

export default ContactPage;