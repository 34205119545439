import React from "react";
import "../assets/css/BodyAbout.css";
import "../assets/css/General.css";
import SectionHeader from "./SectionHeader.js";

import Instructors from "./Instructors.js";
import sensei from "../assets/img/content/sensei-mj.jpg";
import dojo from "../assets/img/content/dojo.jpg";

const BodyAbout = () => {
  return (
    <div className="about-container">
      <SectionHeader title="About Us" />
      <div className="container justify-content-md-center">
        <div className="card flex-md-row mb-4 box-shadow h-md-250">
          <div className="card-body d-flex flex-column align-items-start">
            <strong className="d-inline-block mb-2 text-primary">
              Chief Instructor
            </strong>
            <h3 className="mb-0">
              <a className="text-dark" href="mailto:sensei.nckd@gmail.com">
                Sensei Mari-jo Suzuki
              </a>
            </h3>
            <div className="mb-1 text-muted">6th Degree Black Belt</div>
            <p className="card-text mb-auto">
              Sensei Mari-jo has a 6th degree black belt (Rokudan) and Renshi title with Seito Shito Ryu.
              Her focus is to train memebers in the art of Seito Shito Ryu, but
              is most proud of her students that have taken the philosophy and
              guiding principles of karate and applied them to their every day
              lives.
            </p>
          </div>
          <img
            className="card-img-right flex-auto d-none d-md-block"
            src={sensei}
            alt="sensei"
          />
        </div>

        <div className="card ">
          <div className="card-body d-flex flex-column align-items-start">
            <strong className="d-inline-block mb-2 text-success">
              The Dojo
            </strong>
            <h3 className="mb-0">
              <a className="text-dark" href="https://goo.gl/maps/" target="new">
                Tice Valley Gym
              </a>
            </h3>
            <div className="mb-1 text-muted"></div>
            <p className="card-text mb-auto">
              NCK serves students from the entire East Bay, including, but not
              limited to, Danville, San Ramon, Moraga, Lafayette, La Morinda,
              Walnut Creek, as well as Alameda and Solano Counties.
            </p>
            <p className="card-text mb-auto">
              Our classes are conducted in the new and roomy Exercise Room in the Tice Valley Gym in Walnut Creek, CA. Parking is fere in the large parking lot. Please don't
              hesitate to contact us if you have any questions. 
            </p>
          </div>
          <img className="card-img-bottom" src={dojo} alt="dojo" />
        </div>

        <Instructors />
      </div>
    </div>
  );
};

export default BodyAbout;
