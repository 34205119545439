import React from "react";
import "../assets/css/Navbar.css";

import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-faded">
      <button
        className="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="navbar-collapse collapse" id="navbar">
        <ul className="navbar-nav mx-auto">
          <li className="nav-item nav-item2">
            <Link to="/" className="nav-link nav-link2">
              Home<span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item nav-item2">
            <Link to="/schedule" className="nav-link nav-link2">
              Schedule
            </Link>
          </li>
          <li className="nav-item nav-item2">
            <Link to="/pricing" className="nav-link nav-link2">
              Pricing
            </Link>
          </li>
          <li className="nav-item nav-item2">
            <Link to="/gettingstarted" className="nav-link nav-link2">
              Getting Started
            </Link>
          </li>
          <li className="nav-item nav-item2">
            <Link to="/karate" className="nav-link nav-link2">
              Karate
            </Link>
          </li>
          <li className="nav-item nav-item2">
            <Link to="/about" className="nav-link nav-link2">
              About
            </Link>
          </li>
          <li className="nav-item nav-item2">
            <Link to="/contact" className="nav-link nav-link2">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
