import React from 'react';

class Language extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="alert alert-danger" role="alert">
                    This section is still under construction. We apologize for any inconveniences.
                </div>
            </div>
        );
    }
}

export default Language;