import React from "react";
import "../assets/css/CustomJumbotron.css";

class CustomJumbotron extends React.Component {
  render() {
    return (
      <div className="jumbotron clearfix">
        <div className="row">
          <div className="left-box col-sm-6"></div>
          <div className="right-box col-sm-6">
            <dl className="dl-horizontal">
              <dt>Non-Profit</dt>
              <dd>
                Northern California Karate-do was founded in 2008 as a public
                benefit, non-profit, 501(c)(3) corporation.
              </dd>
              <dt>Traditional</dt>
              <dd>
                We focus on preserving Seito Shito-Ryu Karate, while
                simultaneously incorporating strength and conditioning drills
                and exercises.
              </dd>
              <dt>Individual Focused</dt>
              <dd>
                Although competition is great, our mission is to help each
                person improve their body and mind.
              </dd>
              <dt>On A Mission</dt>
              <dd>
                To promote, develop and advance athletic excellence and
                sportsmanship in sport karate; provide funding to members
                participating and/or competing in sport karate events; and to
                increase the awareness of concepts and philosophy of traditional
                Japanese karate to the general community.
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomJumbotron;
