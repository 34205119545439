import React from 'react';

const json = require('../../assets/data/stances.json');

class Stances extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            data: json
        }
    }
    
    render() {
        return (
            <div className="container">
                <table className="table">
                    <caption>Stances</caption>
                    
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Meaning</th>
                        </tr>
                    </thead>
                        <tbody>
                            { console.log(this.state.data) }
                            { this.state.data.stances.map( (stance, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{ stance.name }</td>
                                        <td>{ stance.meaning }</td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                </table>
            </div>
        );
    }
}

export default Stances;