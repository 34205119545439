import React from "react";
import "../assets/css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer container-fluid border-top">
      <div className="footer-top text-center row">
        <div className="footer-item col-6 col-md col-sm">
          <ul className="list-inline">
            <li>
              <h5>Links</h5>
            </li>
            <li>
              <a href="http://seitoshitoryu.com" target="new">
                Seito Shito Ryu HQ
              </a>
            </li>
            <li>
              <a href="http://usankf.org" target="new">
                USA National Karate Federation
              </a>
            </li>
            <li>
              <a href="http://wkf.net" target="new">
                World Karate Federation
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-item col-6 col-md col-sm">
          <ul className="list-inline">
            <li>
              <h5>Social</h5>
            </li>
            <li>
              <a href="tel:9257256212">
                <span className="fa fa-mobile"></span> (925) 725-6212
              </a>
            </li>
            <li>
              <a href="mailto:sensei.nckd@gmail.com">
                <span className="fa fa-envelope"></span> sensei.nckd@gmail.com
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/Northern-California-Karate-do-153844190853/"
                target="_new"
              >
                <i className="fab fa-facebook-f"></i> Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/norcal_karate_do/"
                target="_new"
              >
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-item col-6 col-md col-sm">
          <ul className="list-inline">
            <li>
              <h5>Location</h5>
            </li>
            <li>
              <a href="https://goo.gl/maps/171obbMKam75AkXq9" target="_new">
                Tice Valley Gym
              </a>
            </li>

            <li>2055 Tice Valley Blvd</li>
            <li>Walnut Creek, CA 94595</li>
          </ul>
        </div>

        <div className="footer-item-end col-6 col-md col-sm">
          <div className="location-frame">
            <iframe
              title="location_frame"
              name="location_frame"
              className="container-fluid"
              id="map-container"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12597.4391946313!2d-122.0699168!3d37.8752676!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b903b39dc3acb0!2sTice%20Valley%20Community%20Gym!5e0!3m2!1sen!2sus!4v1648966908539!5m2!1sen!2sus"
              width="600"
              height="450"
              allowfullscreen="no"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <hr />
      </div>

      <div className="container-fluid">
        <p className="quote">
          "The ultimate aim of karate lies not in victory nor defeat, but in the
          perfection of the character of its participants."
        </p>
      </div>

      <div className="footer-copyright">
        <div className="text-center">
          © 2017-2024 Copyright: NorcalKarateDo.org
        </div>
      </div>
    </footer>
  );
};

export default Footer;
