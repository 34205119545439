import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodySchedule from '../components/BodySchedule.js';

class SchedulePage extends React.Component {
    render() {
        return (
            <div> 
                <Header />
                <BodySchedule />
                <Footer />
            </div>
        );
    }
}

export default SchedulePage;