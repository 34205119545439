import React from "react";

import SectionHeader from "./SectionHeader.js";
import "../assets/css/BodyPricing.css";
import "../assets/css/General.css";

const BodyPricing = () => {
  return (
    <div className="body-pricing container">
      <SectionHeader title="Pricing" />
      <br />
      <div class="alert alert-secondary" role="alert">
        We currently accept cash and check payments, but are in the process of
        establishing online-payment methods. Please let us know if you have any
        questions or concerns.
      </div>

      <div className="card-deck mb-3 pricing-container">
        <div className="card mb-4 box-shadow ">
          <h4 className="card-header text-center">1 Class / Week</h4>
          <p className="card-text">
            <ul className="list-group list-group-flush text-center">
              <li className="list-group-item">$70 per month</li>
              <li className="list-group-item">No Contract</li>
              <li className="list-group-item">
                Can pro-rate with a 2-week notice as long as absence is at least
                2 consecutive weeks.
              </li>
            </ul>
          </p>
        </div>

        <div className="card mb-4 box-shadow ">
          <h4 className="card-header text-center">2 Classes / Week</h4>
          <p className="card-text">
            <ul className="list-group list-group-flush text-center">
              <li className="list-group-item">$95 per month</li>
              <li className="list-group-item">No Contract</li>
              <li className="list-group-item">
                Can pro-rate with a 2-week notice as long as absence is at least
                2 consecutive weeks.
              </li>
            </ul>
          </p>
        </div>

<div className="card mb-4 box-shadow ">
            <h4 className="card-header text-center">3 Classes / Week</h4>
            <p className="card-text">
              <ul className="list-group list-group-flush text-center">
                <li className="list-group-item">$120 per month</li>
                <li className="list-group-item">No Contract</li>
                <li className="list-group-item">
                  Can pro-rate with a 2-week notice as long as absence is at least
                 2 consecutive weeks.
                </li>
              </ul>
            </p>
          </div>

        <div className="card mb-4 box-shadow">
          <h4 className="card-header text-center">Unlimited</h4>
          <p className="card-text">
            <ul class="list-group list-group-flush text-center">
              <li className="list-group-item">N/A</li>
              <li className="list-group-item">N/A</li>
              <li className="list-group-item">N/A</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BodyPricing;
