import React from "react";
import "../assets/css/BodyContact.css";
import "../assets/css/General.css";

import SectionHeader from "./SectionHeader.js";
//import ContactForm from './ContactForm';

const BodyContact = () => {
  return (
    <div className="contact-container">
      <SectionHeader title="Contact Us" />

      <div className="container">
        <div className="card contact-card">
          <div className="card-header">NorCal Karate Do</div>

          <div className="card-body">
            <div className="row">
              <div className="col-md">
                <a
                  href="mailto:sensei.nckd@gmail.com"
                  className="btn btn-info btn-lg"
                 >
                  <span className="fa fa-envelope"></span>
                  sensei.nckd@gmail.com
                </a>
              </div>
              <br />
              <div className="col-md">
                <a href="tel:9257256212" className="btn btn-info btn-lg">
                  <span className="fa fa-mobile"></span> (925) 725-6212
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyContact;
