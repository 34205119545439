import React from 'react';
import Navbar from './Navbar.js';
import '../assets/css/Header.css';
import logo from '../assets/img/content/seito_logo.png';

class Header extends React.Component {
  render() {
    return (
    <header className="header">

          <div className="header-title text-center">
            <h1 className="heading">Northern California Karate Do</h1>
          </div>
          <div className="header-logo center-stuff"><img src={logo} alt="logo"/></div>
          <Navbar />
    </header>
    )
  }
}

export default Header;