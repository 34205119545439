import React from "react";

import SectionHeader from "./SectionHeader.js";
import "../assets/css/BodySchedule.css";
import "../assets/css/General.css";

const BodySchedule = () => {
  return (
    <div className="schedule-container">
      <SectionHeader title="Schedule" />

      <div className="container">
        <div className="alert text-center alert-info" role="alert">
          To view the annual calendar,{" "}
          <a href="https://calendar.google.com/calendar/b/3?cid=c2Vuc2VpLm5ja2RAZ21haWwuY29t">
            please go here
          </a>
        </div>

        <div className="card border-0">
          <p className="card-title">Sunday</p>
          <p className="card-text">No class.</p>
          <p className="card-title">Monday</p>
          <p className="card-text">No class.</p>
          <p className="card-title">Tuesday</p>
          <p className="card-text">No class.</p>
          <p className="card-title">Wednesday</p>
          <p className="card-text">7:30pm-8:30pm - Int/Adv</p>
          <p className="card-title">Thursday</p>
          <p className="card-text">No class.</p>
          <p className="card-title">Friday</p>
          <p className="card-text">No class.</p>
          <p className="card-title">Saturday</p>
          <p className="card-text">
            10:30am-11:30am - Beg/Nov, 11:30am-12:30pm Open
          </p>
        </div>
      </div>
    </div>
  );
};

export default BodySchedule;
