import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodyPricing from '../components/BodyPricing.js';

import '../assets/css/Body.css';

class PricingPage extends React.Component {
    render() {
        return (
            <div>
                <Header />
                
                <BodyPricing />
                
                <Footer />
            </div>
            );
    }
}

export default PricingPage;