import React from "react";
import photo from "../assets/img/content/dojo-group-2.jpg";
import photo2 from "../assets/img/content/teaching.jpg";
import { Link } from "react-router-dom";
import "../assets/css/UpdatesFeed.css";

class UpdatesFeed extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-xs-12 row-eq-height">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Updates</h5>
              </div>
              <div className="card-body">
                <div className="update-body">
                  <img src={photo} alt="info" />
            
                  <p className="card-text">Got a question? Feel free to contact us anytime.</p>

                  <Link to="/contact" className="btn btn-primary">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-6 col-xs-12 row-eq-height">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">New to Karate?</h5>
              </div>
              <div className="card-body">
                <div className="update-body">
                  <img src={photo2} alt="teaching a student" />
                  <p className="card-text">
                    We welcome people of all ages to try traditional Karate. Our
                    teachers are both knowledgeable in the culture, history, and
                    even the competitive (sport) side of Japanese Karate. If you
                    are experienced in another martial art or a similar style,
                    we hope that you will enjoy learning something new. Please
                    contact us today to schedule an introductory class.
                  </p>
                  <Link to="/gettingstarted" className="btn btn-primary">
                    Get Started Now!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdatesFeed;
