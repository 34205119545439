import React from 'react';
const jsonData = require('../../assets/data/kata.json');

class Kata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: jsonData
    }
  }

  getKata(data) {
      data.map( (kata) => {
          return (
              <tr>
                  <td>{ kata.name }</td>
                  <td>{ kata.extra }</td>
              </tr>
          )
      })
  }

    render() {
        return (
          <div className="container">
              <table className="table">
                  <caption>Itosu lineage</caption>

                  <thead className="thead-dark">
                      <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Extra</th>
                      </tr>
                  </thead>
                      <tbody>
                          { console.log(this.state.data) }
                          { this.state.data.itosu.map( (kata, index) => {
                              return (
                                  <tr key={index}>
                                      <td>{index}</td>
                                      <td>{ kata.name }</td>
                                      <td>{ kata.extra }</td>
                                  </tr>
                              )
                          }) }
                      </tbody>
              </table>
              <table className="table">
                  <caption>Higaonna Lineage</caption>

                  <thead className="thead-dark">
                      <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Extra</th>
                      </tr>
                  </thead>
                      <tbody>
                          { console.log(this.state.data) }
                          { this.state.data.higaonna.map( (kata, index) => {
                              return (
                                  <tr key={index}>
                                      <td>{index}</td>
                                      <td>{ kata.name }</td>
                                      <td>{ kata.extra }</td>
                                  </tr>
                              )
                          }) }
                      </tbody>
              </table>
          </div>
        );
    }
}

export default Kata;
