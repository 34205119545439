import React from 'react';
import '../assets/css/SectionHeader.css';

class SectionHeader extends React.Component {
    render() {
        return(
            <div className="container">
                <div className="section-header card">
                    <div className="section-body card-body">
                        <h1>{this.props.title}</h1>
                    </div>
                </div>
            </div>
            )
    }
}

export default SectionHeader;