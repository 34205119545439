import React from 'react';
import Table from '../Table';
const json = require('../../assets/data/strikes.json');


class Strikes extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            data: json
        }
    }
    
    render() {
        return (
            <div className="container">
                <Table caption="Hand and Foot Techniques" data={this.state.data.hand_and_foot} />
                <Table caption="Thrusting Techniques" data={this.state.data.thrusting_techniques} />
                <Table caption="Striking Techniques" data={this.state.data.striking_techniques} />
                <Table caption="Foot and Leg Techniques" data={this.state.data.foot_and_leg} />
            </div>
        );
    }
}

export default Strikes;