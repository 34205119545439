import request from 'superagent'
import moment from 'moment';

const CALENDAR_ID = 'sensei.nckd@gmail.com';
const API_KEY = process.env.REACT_APP_GCAL_API_KEY; 
const CURRENT_TIME = moment().format();

let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?maxResults=12&timeMin=${CURRENT_TIME}&singleEvents=true&key=${API_KEY}`;

export function getEvents (callback) {
  request
    .get(url)
    .end((err, resp) => {
      if (!err) {
        const events = []
        console.log(resp.text);
        
        let json = JSON.parse(resp.text);
        console.dir("JSON OBJECT: " + json);
        
        json.items.map( (event) => {
          console.dir(event);
          if(event.status !== "cancelled") {
            events.push({
            start: event.start.date || event.start.dateTime,
            end: event.end.time || event.end.dateTime,
            title: event.summary,
          })  
          }
          
          return events
        })
        callback(events)
      }
    })
}