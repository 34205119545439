import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodyGettingStarted from '../components/BodyGettingStarted.js';
import SectionHeader from '../components/SectionHeader.js';

class GettingStarted extends React.Component {
    render() {
        return (
            <div> 
                <Header />
                <SectionHeader title="Get Started Now"/>
                <BodyGettingStarted />
                <Footer />
            </div>
        );
    }
}

export default GettingStarted;