import React from 'react';

import Table from '../Table';
const jsonData = require('../../assets/data/concepts.json');

class Concepts extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: jsonData
        }
    }
    
    getConcepts(data) {
        data.map( (concept) => {
            return (
                <tr>
                    <td>{ concept.name }</td>
                    <td>{ concept.meaning }</td>
                </tr>
            )
        })
    }
    
    render() {
        return (
            <div className="container">
                <Table caption="Concepts" data={this.state.data.concepts} />
                <Table caption="Tenpo Gosoku no Ho" data={this.state.data.tenpo_gosoku_no_ho}/>
                <Table caption="Uke no Gogenri" data={this.state.data.uke_no_gogenri} />
                <Table caption="Kihon Kumite" data={this.state.data.kihon_kumite} />
                <Table caption="Oyo Kumite" data={this.state.data.oyo_kumite} />
            </div>
        );
    }
}

export default Concepts;