import React from 'react';
import '../assets/css/Event.css';

class Event extends React.Component {
    render() {
        return(
            <li className="media li-event">
                <div className="media-left">
                    <div className="card text-center date">
                        <div className="card-heading month bg-danger">
                            <span className="card-title strong">
                                {this.props.month}
                            </span>
                        </div>
                        <div className="card-body day text-center">
                            {this.props.day}
                        </div>
                    </div>
                </div>
                <div className="media-body">
                    <h4 className="media-heading">
                        {this.props.time}
                    </h4>
                    <p className="text-nowrap">
                        {this.props.title}
                    </p>
                </div>
            </li>
        );
    }
}

export default Event;