import React from 'react';
import '../assets/css/Home.css';

import CustomJumbotron from './CustomJumbotron.js';
import InstagramFeed from './InstagramFeed.js';
import UpdatesFeed from './UpdatesFeed.js';
import EventsFeed from './EventsFeed.js';

class BodyHome extends React.Component {
  render() {
    return ( 
      <div className="body-container">
          
          <br/>
          <div className="container-fluid">
            <CustomJumbotron/>
          </div>
          
          <UpdatesFeed />
          <br/>
          
          <div className="container-fluid">
            <EventsFeed />
          </div>
          
          
          <br/>
          
          <br/>
          <InstagramFeed />
          
      </div>
  );
 }
}
export default BodyHome;