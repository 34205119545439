import React from 'react';
import Table from '../Table';
const json = require('../../assets/data/blocks.json');

class Blocks extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: json
        }
    }
    
    render() {
        return (
            <div className="container">
                
                <Table caption="High Blocking Techniques" data={this.state.data.upper_level_blocking} />
                <Table caption="Mid Level Blocking Techniques" data={this.state.data.middle_level_blocking} />
                <Table caption="Low Level Blocking Techniques" data={this.state.data.lower_level_blocking} />
             
            </div>
        );
    }
}

export default Blocks;