import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BodyKarate from '../components/BodyKarate.js';

class KaratePage extends React.Component {
    render() {
        return (
            <div> 
                <Header />
                <BodyKarate />
                <Footer />
            </div>
        );
    }
}

export default KaratePage;